var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.local
    ? _c("div", { staticClass: "version-observer" }, [
        _c("div", { staticClass: "version-observer__version" }, [
          _vm._v(" " + _vm._s(_vm.local) + " "),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }