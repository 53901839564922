<template>
  <div v-if="!!local" class="version-observer">
    <div class="version-observer__version">
      {{ local }}
    </div>
  </div>
</template>

<script>
import { db } from "@/firebase"
import getRandomInt from "@shared/helpers/getRandomInt"

const VERSION_UPDATE_MESSAGE_TEMPLATE = version =>
  `NEW VERSION ${version} IS AVAILABLE.`

const VERSION_UPDATE_PRODUCTION_MESSAGE_TEMPLATE = version =>
  `A NEW VERSION ${version} IS AVAILABLE, YOU MUST CLICK BELOW TO CONTINUE USING WEVE`

export default {
  name: "VersionObserver",
  data() {
    return {
      local: null,
      remote: null
    }
  },
  created() {
    this.local = this.$app.version
    db.ref("app/version").on("value", snapshot => {
      this.remote = snapshot.val()
    })
  },
  watch: {
    remote: {
      async handler(newValue, oldValue) {
        try {
          if (
            newValue &&
            newValue !== oldValue &&
            (this.$app.isSmallerThan(newValue) ||
              this.$app.isGreaterThan(newValue))
          ) {
            // to prevent users from refreshing at the same time
            setTimeout(async () => {
              this.$queue.enq({
                priority: 3,
                action: async () => {
                  if (process.env.VUE_APP_LET_SKIP_UPDATE === "true") {
                    await this.$confirm({
                      title: VERSION_UPDATE_MESSAGE_TEMPLATE(newValue),
                      message: "REFRESH NOW?",
                      yesText: "Yes, refresh"
                    })
                  } else {
                    await this.$info(
                      VERSION_UPDATE_PRODUCTION_MESSAGE_TEMPLATE(newValue),
                      undefined,
                      true
                    )
                  }
                  window.location.reload(true)
                }
              })
            }, getRandomInt(0, 15000))
          }
        } catch (e) {
          console.warn(e)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.version-observer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100px;
  height: 20px;
  z-index: 999;

  &__version {
    opacity: 0.25;
    &:hover {
      opacity: 1;
    }
    display: inline-block;
    height: 20px;
    font-weight: bold;
    padding-left: 5px;
    padding-right: 5px;
    background-color: rgba(255, 255, 255, 0.3);
    line-height: 18px;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.5);
  }
}
</style>
